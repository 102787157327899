<template>
  <div class="pagination-arrows">
    <button
      :style="paginationButtonsStyles"
      :class="{ previous__button: true, disable__button: isFirstPage }"
      :disabled="isFirstPage"
      @click.prevent="$emit('prev', pageIndex)"
    >
      <i data-lucide="chevron-left" :style="{ color: firstColor }"></i>
    </button>

    <button
      :style="paginationButtonsStyles"
      :class="{ next__button: true, disable__button: isLastPage }"
      :disabled="isLastPage"
      @click.prevent="$emit('next', pageIndex)"
    >
      <i data-lucide="chevron-right" :style="{ color: firstColor }"></i>
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { addAlpha } from "@/helpers/utils";

const emit = defineEmits(["next", "prev"]);

const props = defineProps({
  pageIndex: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  styling: {
    type: Object,
    required: true,
  },
  isFreeUser: {
    type: Boolean,
    required: true,
  },
  firstColor: {
    type: String,
    required: true,
  },
  secondColor: {
    type: String,
    required: true,
  },
});

const progressColor = ref(addAlpha(props.styling.progressColor, 0.1));
const disabledColor = ref(addAlpha(props.firstColor, 0.3));

const isFirstPage = computed(() => props.pageIndex === 0);
const isLastPage = computed(() => props.pageIndex === props.totalPages - 1);

const paginationButtonsStyles = computed(() => ({
  "border-color": props.firstColor || props.styling.progressColor || "#4F3895",
  "background-color": props.secondColor || progressColor.value || "#4F3895",
}));
</script>

<style>
.pagination-arrows {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.shift-arrows {
  bottom: 9px;
  right: 230px;
}

.pagination-arrows button {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid;
  overflow: hidden;
  padding: 0;
}

.pagination-arrows :hover {
  background-color: v-bind(disabledColor);
}
.pagination-arrows button svg {
  width: 100%;
  height: 100%;
  padding: 8px;
  color: var(--font-color);
}

.disable__svg {
  color: v-bind(disabledColor) !important;
}

.disable__button {
  cursor: auto !important;
  border-color: v-bind(disabledColor) !important;
}

.disable__button svg {
  color: v-bind(disabledColor) !important;
}

.disable__button :hover {
  background-color: unset;
}

.lucide-chevron-left path {
  d: path("m 14 18 l -6 -6 l 6 -6");
}

.lucide-chevron-right path {
  d: path("m 10 18 l 6 -6 l -6 -6");
}

@media only screen and (max-width: 576px) {
  .pagination-arrows {
    width: 90px;
    margin: 0 0.5rem;
  }
}
</style>
