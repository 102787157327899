<!-- This countdown is visible to the submitter (on survey page ) -->
<template>
  <div v-if="canShowCountdown" class="countdown ff-inter">
    <AlarmClockIcon />
    <p v-if="totalTime > 0">{{ countdown }}</p>
    <p v-else class="timeout__title">{{ timeoutTitle }}</p>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import AlarmClockIcon from "@/components/shared/icons-component/alarm-clock-icon.vue";
import { useCountdownTimer } from "@/helpers/composables/use-countdown-timer.js";

const props = defineProps({
  timer: {
    type: Object,
    required: true,
    note: "contains all the required propeties used for timer",
  },
});

const { timeoutTitle, countdownHours, countdownMinutes, countdownSeconds } =
  useCountdownTimer(props.timer);

// Emits
const emit = defineEmits(["time-up"]);

// States
const totalTime = ref(0);
const isSubmittedBeforeTime = ref(true);
const intervalId = ref(null);

// Computed
const countdown = computed(() => {
  const hrs = Math.floor(totalTime.value / 3600);
  const mins = Math.floor((totalTime.value % 3600) / 60);
  const secs = totalTime.value % 60;

  const formattedHrs = formatUnit(hrs, "h");
  const formattedMins = formatUnit(mins, "m");
  const formattedSec = formatUnit(secs, "s");

  return `${
    canShowHours.value ? formattedHrs : ""
  } ${formattedMins} ${formattedSec}`;
});

const canShowHours = computed(() => {
  return !(countdownHours.value[0] === 0 && countdownHours.value[1] === 0);
});

const canShowCountdown = computed(() => {
  return (
    (totalTime.value !== 0 || isSubmittedBeforeTime.value) && intervalId.value
  );
});

// Methods
const formatUnit = (value, unit) => {
  return `${String(value).padStart(2, "0")}${unit}`;
};

const initialTime = () => {
  const hours = parseInt(countdownHours.value.join(""));
  const minutes = parseInt(countdownMinutes.value.join(""));
  const seconds = parseInt(countdownSeconds.value.join(""));
  return hours * 3600 + minutes * 60 + seconds;
};

const startCountdown = () => {
  if (!localStorage.getItem("remaining-countdown-time")) {
    totalTime.value = initialTime(); //start new countdown
  }

  clearInterval(intervalId.value);

  intervalId.value = setInterval(() => {
    if (totalTime.value > 0) {
      totalTime.value--;
      localStorage.setItem("remaining-countdown-time", totalTime.value);
    } else {
      stopCountdown();
      emit("time-up");
    }
  }, 1000);
};

const stopCountdown = () => {
  if (totalTime.value !== 0) {
    isSubmittedBeforeTime.value = false;
  }

  const timeTaken = initialTime() - totalTime.value;
  totalTime.value = 0;
  clearInterval(intervalId.value);
  localStorage.removeItem("remaining-countdown-time");

  return timeTaken;
};

// Hooks
onMounted(() => {
  const storedRemainingTime = parseInt(
    localStorage.getItem("remaining-countdown-time"),
  );

  if (storedRemainingTime && storedRemainingTime > 0) {
    totalTime.value = storedRemainingTime; //resume countdown from where user left it

    startCountdown();
  } else {
    localStorage.removeItem("remaining-countdown-time");
  }
});

onUnmounted(() => {
  clearInterval(intervalId.value);
});

// Expose
defineExpose({ startCountdown, stopCountdown });
</script>

<style scoped>
.countdown {
  max-width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  gap: 8px;
  border-radius: 4px;
  background: var(--primary-color-ten-opacity);
  border: 1px solid var(--primary-color-twenty-opacity);
}

.countdown p {
  width: 80px;
  color: #101828;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-wrap: nowrap;
  text-align: center;
  margin: 0;
}

.timeout__title {
  min-width: 80px;
  width: max-content !important;
}
</style>
